export const classTable = [
    "Brute",
    "Rapscallion",
    "Buccaneer",
    "Swashbuckler",
    "Zealot",
    "Sorcerer" ]

export const statLookupTable = {
    "1":"-3",
    "2":"-3",
    "3":"-3",
    "4":"-3",
    "5":"-2",
    "6":"-2",
    "7":"-1",
    "8":"-1",
    "9":"0",
    "10":"0",
    "11":"0",
    "12":"0",
    "13":"+1",
    "14":"+1",
    "15":"+2",
    "16":"+2",
    "17":"+3",
    "18":"+3",
    "19":"+3",
    "20":"+3" }
    
export const distinctiveFlawsTable = 
    ["Drunken Lush",
    "Stubborn",
    "Mocking sardonic cheer",
    "Way too loud",
    "Stupid",
    "Coward",
    "Cocky",
    "Slightly deranged",
    "Aggressive",
    "Anxious",
    "Cheater",
    "Selfish",
    "Lazy",
    "Hedonistic",
    "Impulsive",
    "Ostentatious",
    "Paranoid",
    "Pretentious",
    "Sadistic",
    "Disloyal" ]

export const physicalAilmentTable = 
    ["Cursed: visibly part skeleton ghost/water/flames/coral",
    "Missing an eye",
    "Matted, dreaded hair",
    "Missing a leg: pegleg or crutch",
    "Missing a hand: hook or claw instead.",
    "Missing an ear",
    "Many, many tattoos",
    "Never blinks. Ever.",
    "Rotten or broken teeth",
    "Twitches constantly, especially trigger finger",
    "A nigh incurable case of scurvy: permanently bleeding gums",
    "Infested with bugs",
    "Gnarly facial scar",
    "Hideously ugly",
    "Corpulent",
    "Increasingly gangrenous",
    "Putrid, bilge stench",
    "Contagious",
    "Gaunt & frail",
    "So good looking people are jealous"]

export const idiosyncraciesTable = 
    ["You smoke constantly, and cough even more.",
    "“Functioning” alcoholic. You’re probably drunk right now.",
    "You bet on everything possible.",
    "Constantly counting. Teeth, cannon balls... everything",
    "Rats are your favorite meal.",
    "You know every tall tale ever told. You make sure everyone else knows you know them.",
    "You are afraid of prime numbers larger than 3. d20 rolls of 5, 7, 11, 13, and 17 fill you with superstitious terror.",
    "You become a murderous grump when hungry.",
    "Habitual procrastinator... if you even finish the task.",
    "You are a voluntary insomniac. Sleep is for the dead.",
    "You prefer to shoot first and never ask questions.",
    "Overly, annoyingly religious.",
    "You collect something. You talk to your collection. They are your friends.",
    "Always trying to trick your crew mates, just for fun.",
    "Why pay for anything when you can steal it?",
    "Talk to yourself when alone, but you often think you are alone when you aren’t.",
    "You secretly enjoy the taste of human flesh.",
    "You always say you “know the right way”, but are prone to getting lost.",
    "You blame everyone but yourself for all of your mistakes.",
    "Extremely obsessive with tasks and relationships."]

export const unfortunateIncidentsTable = 
    ["Your loved ones were burned alive. Revenge is imminent.",
    "You are known pirate. You face the gallows if caught.",
    "You betrayed former crew mates. Now they hunt you.",
    "You were once marooned on an island for far too long. The voices must be real.",
    "You stole a ship. The owner wants your money or your head, but will settle for both.",
    "You escaped captivity, and will never go back.",
    "A close relative has become your greatest enemy.",
    "The last three ships you crewed all sank.",
    "Your last crew was killed by undead. They let you live on purpose.",
    "Political leaders hold your loved one(s) captive.",
    "An undead spirit you don't like possesses you regularly.",
    "You wronged an infamous pirate lord.",
    "You narrowly escaped a cannibalistic ending, but you didn’t escape that smell.",
    "You slaughtered them. Like animals.",
    "You are the mysterious lone survivor of a treasure expedition gone awry.",
    "[d2] 1: Failed mutineer. 2: Successful mutineer.",
    "A silent ghost haunts you. It is always there, but only you can see it.",
    "You deserted the military, but you’re not sure who knows so.",
    "You have no memory before a few days ago.",
    "You died once already, but Hell didn’t want you."]

export const thingOfImportanceTable = 
    ["an animal pelt",
    "an oyster pearl",
    "a silver locket",
    "a conch shell",
    "a pipe carved from wood",
    "a pipe carved from bone",
    "a small jade figurine",
    "a shard of crystal",
    "an ancient gold coin",
    "a ruined piece of a treasure map",
    "a treasure map of an unknown place",
    "a diary written by an ancestor",
    "a silver ring",
    "an ivory chess piece",
    "a sea creature carved from obsidian",
    "a spherical gem that functions like a prism",
    "a jar containing a severed hand, eyeball, or organ",
    "a necklace made of bones and feathers",
    "a book of scripture",
    "a novel you loved as a child",
    "a silk handkerchief from a distant land",
    "a pouch containing different animal teeth",
    "an old fillet knife",
    "a fossil of an extinct fish",
    "a piece of colorful coral",
    "a small ship in a bottle",
    "a letter from a loved one",
    "the journal of a dead explorer",
    "a stone embossed with a beautiful mermaid",
    "a vial of holy water from clergy in your hometown",
    "a jar with the remains of a small squid",
    "precious salts and spices, in a tiny chest",
    "a tankard made from a horn",
    "a jar of the finest tobacco",
    "a golden letter opener",
    "a small, cast bronze owl figurine",
    "a collection of sea shells and rocks",
    "a necklace carved from jade",
    "a recently deceased relative’s will, naming you as the sole heir",
    "a drawing of a loved one",
    "a bag of white powder you believe may be magical",
    "an old rusted key with a blue gem that glows in the moonlight",
    "a compass that doesn’t point north",
    "a clay jar you are using as an urn",
    "definitive proof of an enemy’s (or loved one’s) crime",
    "a small golden bell",
    "an old bottle of red wine (Bordeaux, incredible vintage)",
    "a jar of dried jellyfish dust",
    "a multi-colored feather from an exotic bird",
    "a necklace from a loved one",
    "a gold ring that is too small to fit on your fingers",
    "a single diamond earring",
    "a finely made leather eye patch",
    "a set of gardening tools",
    "a dried flower",
    "an animal skull",
    "a human skull",
    "a gem that glows when submerged in seawater",
    "a dinosaur or monster bone or claw",
    "a jar of fireflies",
    "a leather-bound tome in a language you don’t recognize",
    "blueprints to a new type of ship",
    "a carved arrowhead",
    "a stone tablet inscribed with ancient pictographs or hieroglyphs",
    "a perfect cube made of crystal",
    "significant tattoo, d4:1 love, 2 revenge, 3 ancestors, 4 unknown origin",
    "a bottle of perfumed oil",
    "a broken set of manacles",
    "a pistol with one shot meant for someone special",
    "a flag of personal significance",
    "a broken compass with a mysterious significance",
    "a broken spyglass with a scroll or map hidden inside",
    "a length of rope you made",
    "carved gaming pieces",
    "a set of rune stones",
    "a twig from a very old tree",
    "the end of a rope noose taken from a corpse, a very bad memory",
    "a 6' length of chain",
    "4d10 scars from lashes on your back",
    "a long scar on your face",
    "1d4 missing finger and/or toes",
    "a missing eye (roll d2)",
    "a missing limb (roll d4)",
    "a set of keys on a large key ring",
    "a small keg of something valuable (rum, powder, ashes)",
    "a magnifying lens (glass only)",
    "the cork from a bottle, from a special occasion",
    "a cannonball",
    "a deck of cards with 1d4 cards missing and 1d6 “extra” cards",
    "a garment from someone special",
    "a wanted poster, d4:1 legend, 2 loved one, 3 enemy, 4 stranger",
    "a fancy wig",
    "a letter of political importance",
    "tanned whale skin or a jar of blubber",
    "a petrified egg",
    "a monkey paw with 1 finger extended",
    "a memorized poem that sounds like a treasure map",
    "a medallion that might be the top of a staff",
    "a talisman shaped like a snake",
    "a glass vial of dark blood"]

export const backgroundTable = 
    ["sailor: 2d6 x 10s & a reason to go to sea ",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "sailor: 2d6 x 10s & a reason to go to sea",
    "actor: 2d6 x 10s & a play or book",
    "apothecary: 2d6 x 10s & healing kit",
    "artist: 2d6 x 10s & art supplies",
    "assassin: 2d6 x 10s & a dagger",
    "bandit: 2d6 x 10s & a club",
    "barkeep: 2d6 x 10s & a flask of fine rum",
    "blacksmith: 2d6 x 10s & a set of files and tools",
    "bosun: 2d6 x 10s & the respect of some crew",
    "business person: 3d6 x 10s & a profitable past",
    "cabin boy/girl: 2d4 x 10s & a small toy or trinket",
    "captain: 2d8 x 10s & a spyglass",
    "former captive: 2d4 x 10s & your freedom",
    "castaway: 2d4 x 10s & a keepsake from the island",
    "chef: 2d6 x 10s & a book of recipes",
    "cook: 2d6 x 10s & some fine cooking spices",
    "craftsman: 2d6 x 10s & a set of tools",
    "criminal: 1d10 x 10s & lockpick or a crowbar",
    "cultist: 2d6 x 10s & a book of scripture",
    "deserter: 2d4 x 10s & a small bounty on your head",
    "doctor: 2d6 x 10s & a medical kit",
    "explorer: 2d6 x 10s & a map and compass",
    "farmer: 2d4 x 10s & a reason to leave your farm",
    "first mate: 2d6 x 10s & a loyal friend",
    "gambler: 1d12 x 10s & a sizable debt",
    "grave robber: 2d6 x 10s & something from a corpse",
    "guard: 2d6 x 10s & a keen eye for mischief",
    "gunner: 2d6 x 10s & skill at shooting",
    "harlot: 2d8 x 10s & an admirer and syphilis",
    "heretic: 2d4 x 10s & a blasphemous disposition",
    "homemaker: 2d6 x 10s & a loved one back home",
    "hunter: 2d6 x 10s & a hunting knife",
    "former servant: 2d4 x 10s & someone looking for you",
    "innkeeper: 3d4 x 10s & a small inn somewhere",
    "landowner: 3d6 x 10s & property somewhere",
    "loner: 2d6 x 10s & a dislike of others",
    "cartographer: 2d6 x 10s & map making tools",
    "marine: 2d6 x 10s & a military cutlass",
    "medic: 2d6 x 10s & a medical kit",
    "merchant: 2d8 x 10s & good negotiating skills",
    "military: 2d6 x 10s & combat training",
    "missionary: 2d4 x 10s & a holy symbol and scripture",
    "monk: 2d6 x 10s & a holy symbol and scripture",
    "musician: 2d6 x 10s & [roll on instrument table on page 27]",
    "naval deserter: 2d4 x 10s & a price on your head",
    "navigator: 2d6 x 10s & a compass",
    "noble: 3d6 x 10s & good manners",
    "nobody 2d4 x 10s & a desire for a purpose",
    "nurse: 2d6 x 10s & a medical kit",
    "officer: 2d8 x 10s & a cutlass and military training",
    "orphan: 2d4 x 10s & a longing for new parental figures",
    "performer: 2d6 x 10s & [roll on instrument table on page 7]",
    "philosopher: 2d6 x 10s & a book and a puzzling disposition",
    "pilot: 2d6 x 10s & a compass",
    "pirate: 2d6 x 10s & an empty bottle of rum",
    "politician: 2d6 x 10s & skill at deception",
    "priest: 2d6 x 10s & a holy symbol and scripture",
    "privateer: 2d6 x 10s & a letter of marque [roll Faction d4 pg.4]",
    "quartermaster: 2d6 x 10s & the respect of a ship’s crew",
    "refugee: 2d4 x 10s & strong survival instincts",
    "religious follower: 2d4 x 10s & a strong sense of faith",
    "revolutionary: 2d6 x 10s & bold plans and a few allies",
    "rumored sorcerer: 2d6 x 10s & dark and powerful knowledge",
    "runaway: 2d4 x 10s & a desire for a new home",
    "sail maker: 2d6 x 10s & skill with sails",
    "scholar: 2d6 x 10s & a few books",
    "scoundrel: 2d8 x 10s & a few enemies along the way",
    "scout: 2d6 x 10s & keen senses and a map",
    "shaman: 2d4 x 10s & herbs and mystic knowledge",
    "shipwright: 2d6 x 10s & skill with woodworking",
    "smuggler: 2d6 x 10s & a bounty on your head",
    "soldier: 2d6 x 10s & a tactical mind",
    "spy: 2d6 x 10s & a dark cloak",
    "student: 2d4 x 10s & a strong will to learn",
    "surgeon: 2d6 x 10s & a medical kit",
    "thief: 1d12 x 10s & lock picks",
    "vagabond: 2d4 x 10s & a leather backpack",
    "victim: 2d6 x 10s & a haunted past",
    "tribal warrior: 2d4 x 10s & a cultural weapon",
    "whaler: 2d6 x 10s & a harpoon",
    "writer: 2d6 x 10s & ink, quills, and parchment"]

export const containerTable = 
    ["bucket for 4 normal-sized items",
    "bandolier for 6 small-sized items",
    "satchel for 8 normal-sized items",
    "backpack 10 normal-sized items",
    "large sea chest for 20 normal-sized items or one other container of your choice",
    "a dinghy (pg.80) or one of the above of your choice"]

export const cheapGearTable = 
    ["lantern (d6 hours of oil)",
    "d4 candles (1 hour each)",
    "30’ of rope",
    "shovel",
    "medical kit: (stops bleeding/poison/infection and heals d6 HP, Presence + 4 uses)",
    "weighted dice",
    "flint & steel",
    "hammer & nails",
    "mess kit",
    "pipe & tobacco pouch",
    "d6 torches (1 hour each)",
    "a pet [d10]"]

export const valuableGearTable = 
    ["compass",
    "spyglass",
    "fishing rod",
    "1 random Ancient Relic",
    "bottle of fine rum",
    "old pocket watch",
    "blanket & pillow",
    "ink, quill, parchment",
    "worn out book",
    "tent",
    "whetstone",
    "instrument [d10]"]

export const petTable = 
    ["snake",
    "rat",
    "lizard",
    "monkey",
    "parrot",
    "cat",
    "dog",
    "hawk",
    "hermit crab",
    "a fish in a jar"]

export const instrumentTable = 
    ["concertina",
    "drum",
    "flute",
    "fiddle",
    "banjo",
    "horn",
    "hurdy-gurdy",
    "guitar",
    "mandolin",
    "voice of an angel"]

export const relicTable = 
    ["Cross of the Paragon",
    "Conch Shell from the Abyss",
    "Map Inked in Ectoplasm",
    "Will-o’-the-wisp Lantern",
    "Pages from the Necronomicon",
    "Rune Encrusted Flintlock Pistol",
    "Jade Die",
    "Undead Bird",
    "Mermaid Scales",
    "Charon’s Obol",
    "Cup of the Carpenter",
    "Heart of the Sea",
    "Necklace of Eyeballs",
    "Crown of the Sunken Lord",
    "Crystalline Skull",
    "Codex Tablet",
    "Skeleton Key",
    "Mummified Monkey Head",
    "Great Old One Figurine",
    "Broken Compass"]

export const relicDescriptionTable = 
    ["One ally gets +1 to attack and +1 to damage for d6 turns.",
    "Ask a nearby corpse (or any creature that died at sea within 100 miles) one question.",
    "Learn the location of all traps and secret doors within 30' for d4+SPIRIT rounds.",
    "Emit 15' of light or darkness for d6+SPIRIT rounds.",
    "All creatures that can hear your voice test DR14 or lose d4+SPIRIT HP (ignore armor).",
    "One creature you see loses d6+SPIRIT HP (ignore armor). Takes 1 action to reload.",
    "Roll a die. Odd: you gain d8 temporary HP. Even: Choose a creature. It gets +d8 on its next damage roll.",
    "It can speak with animals (dead or alive) for d6+SPIRIT rounds. ",
    "Eat a scale: breathe underwater for d4 hours.",
    "If you are killed, return to life the next round with 1 HP. Disappears after one use.",
    "Choose a creature to regain d6+SPIRIT HP",
    "Create or destroy 15 gallons water or 30 cubic feet of fog.",
    "Become invisible for d6+SPIRIT rounds or until you attack or take damage. Attack and defend with DR6.",
    "A water shield surrounds you. -d2 protection for d2+SPIRIT rounds (in addition to armor).",
    "The skull can hear & repeat the thoughts of a nearby creature for d6+SPIRIT minutes.",
    "Read and understand any language, glyphs, or runes for 1+SPIRIT rounds.",
    "Open any door or lock. Crumbles after 1 use.",
    "The head speaks: 1 creature tests SPIRIT DR12 or must obey a 1 word command.",
    "One human is terrorized for d4 rounds unless they succeed a PRESENCE DR14 test. They can test each round.", 
    "The compass points in the direction of an object you know of for 1+SPIRIT rounds."]

export const clothingTable = 
    ["rags",
    "rags",
    "common clothes",
    "common clothes",
    "old uniform",
    "fancy clothes (You look amazing)",
    "leather armor (-d2 damage)",
    "hide armor (-d2 damage)",
    "chain shirt (-d4 damage, DR +2 on AGILITY tests including defense)",
    "conquistador armor (-d6 damage, DR +4 on AGILITY tests, defense is DR +2. You’ll most likely sink and drown in water.)"]

export const hatTable = 
    ["none",
    "none",
    "none",
    "none",
    "wig",
    "bandanna",
    "cavalier",
    "bicorne",
    "plain tricorne",
    "fancy tricorne",
    "metal lined hat (-1 damage)",
    "conquistador helmet (-1 damage. You can choose to ignore all damage from one attack but the helmet breaks."]

export const weaponTable = 
    ["Marlinspike or Belaying Pin [d4] ",
    "Knife or Bayonet [d4]",
    "Smallsword or Machete [d4]",
    "Cat O’ Nine Tails [d4] 10' reach",
    "Boarding Axe [d6]",
    "Cutlass [d6]",
    "Flintlock Pistol [2d4, reload 2 actions, range 30', Presence + 10 shots]",
    "Finely Crafted Rapier [d8]",
    "Boarding Pike [d10, 10' reach]",
    "Musket [2d6, reload 2 actions, range 150', Presence + 10 Shots]"]

export const bombTable = [
    "smoke bomb (-), smoke cloud, blind for d4 rounds",
    "improvised grenade (d10)",
    "clay grenade (2d8)",
    "iron grenade (3d6)",
    "fire pot (d6), roll d6 each turn: 1-2 it spreads, 6 the fire goes out",
    "stink ball (2d4), Toughness DR12 or poisoned."
    ]

export const firstNamesTable = 
    ["Albert ",
    "Bevil",
    "Christopher",
    "Edmund",
    "Edward",
    "Frederick",
    "Geoffrey",
    "Glies",
    "Henry",
    "Humphrey",
    "Hugh",
    "Jacob",
    "Johan",
    "John",
    "John",
    "Jonathan",
    "Jospeh",
    "Matthew",
    "Paul",
    "Richard",
    "Robert",
    "Roger",
    "Silas",
    "Thomas",
    "Timothy",
    "William",
    "Raphe",
    "Anthony",
    "Nicholas",
    "Cuthbert",
    "Rowland",
    "Michael",
    "Francis",
    "Peter",
    "Alexander",
    "Andrew",
    "Bryan",
    "Simond",
    "Gilbert",
    "Gerard",
    "Bartholemew",
    "Marke",
    "Oswold",
    "Percivell",
    "Steven",
    "Leonard",
    "Bartram",
    "David",
    "Lyonell",
    "Authur",
    "Miles",
    "Archibald",
    "Barnard",
    "Tobir",
    "Daniel",
    "Allen",
    "Conrad",
    "Elizabeth",
    "Isabel",
    "Christian",
    "Barbara",
    "Julian",
    "Joan",
    "Dorothy",
    "Edith",
    "Rachel",
    "Philippa",
    "Margaret",
    "Margery",
    "Emma",
    "Charity",
    "Audrey",
    "Agnes",
    "Susanna",
    "Lucy",
    "Mabel",
    "Helen",
    "Alice",
    "Ellen",
    "Martha",
    "Millicent",
    "Janet",
    "Anne",
    "Sarah",
    "Marion",
    "Rose",
    "Sybil",
    "Mary",
    "Clemence",
    "Cecily",
    "Thomasin",
    "Ursula",
    "Jane",
    "Frances",
    "Frideswide",
    "Fortune",
    "Avis",
    "Catherine",
    "Joyce",
    "Grace",
    "Gillian",
    "Beatrice",
    "Elinor",
    "Bridget",
    "Amy",
    "Judith",
    "Blanche",
    "James",
    "Theodore",
    "Henry",
    "William",
    "Gordon",
    "Agustín",
    "Alonso",
    "Alvaro",
    "Andrés",
    "Antón",
    "Antonio",
    "Baltasar",
    "Barros",
    "Bartolomé",
    "Bastía",
    "Bautista",
    "Benito",
    "Bernardino",
    "Blas",
    "Carlos",
    "Cosme",
    "Cristóbal",
    "Diego",
    "Domingo",
    "Esteban",
    "Eyague",
    "Felipe",
    "Francisco",
    "Frutos",
    "Gabriel",
    "Garci",
    "Gaspar",
    "Gines",
    "Giraldo",
    "Gonzalo",
    "Gregorio",
    "Guillen",
    "Hector",
    "Hernán",
    "Hernando",
    "Jaime",
    "Jerónimo",
    "Jorge",
    "Jose",
    "Juan",
    "Lope",
    "Lorenzo",
    "Lucas",
    "Luis",
    "Manuel",
    "Marcos",
    "Martín",
    "Mateo",
    "Matias",
    "Melchor",
    "Mergildo",
    "Miguel",
    "Nicolas",
    "Pablo",
    "Pascual",
    "Pedro",
    "Rodrigo",
    "Salvador",
    "Sancho",
    "Santiago",
    "Sebastian",
    "Simón",
    "Tomás",
    "Tomé",
    "Truylos",
    "Aad",
    "Aadje",
    "Aafje",
    "Aagje",
    "Aagtje",
    "Aalbert",
    "Aalbrecht",
    "Aalderik",
    "Aaldert",
    "Aaldrik",
    "Aalrik",
    "Aard",
    "Aardse",
    "Aarnout",
    "Aäron",
    "Aart",
    "Aat",
    "Abby",
    "Abdel",
    "Abdoel",
    "Abdoella",
    "Abe",
    "Abel",
    "Abelone",
    "Abeltje",
    "Abraham",
    "Acco",
    "Achiel",
    "Achmed",
    "Ad",
    "Adaemkin",
    "Adalmar",
    "Adam",
    "Adelheid",
    "Adelmar",
    "Adelwijn",
    "Adelwin",
    "Adolf",
    "Adri",
    "Adriaan",
    "Adrie",
    "Agatha",
    "Albert",
    "Albrecht",
    "Aldemar",
    "Aldert",
    "Alex",
    "Alfons",
    "Alfred",
    "Ali",
    "Alida",
    "Almar",
    "Alonso",
    "Alonzo",
    "Alphons",
    "Amber",
    "André",
    "Andreas",
    "Andries",
    "Anna",
    "Anne",
    "Anne-Marie",
    "Anneke",
    "Annelies",
    "Annemieke",
    "Anouk",
    "Ans",
    "Antoon",
    "Arend",
    "Arie",
    "Armando",
    "Arnoud",
    "Arnout",
    "Arthur",
    "Aster",
    "Astrid",
    "Atte",
    "Barbara",
    "Bart",
    "Basiel",
    "Bastiaan",
    "Beatrijs",
    "Benjamin",
    "Bep",
    "Bert",
    "Bo",
    "Bob",
    "Boudewijn",
    "Bram",
    "Calvijn",
    "Carmen",
    "Catharijne",
    "Chris",
    "Christa",
    "Christiaan",
    "Christoffel",
    "Co",
    "Coen",
    "Coenraad",
    "Constantijn",
    "Cor",
    "Cornelia",
    "Cornelis",
    "Corrie",
    "Daan",
    "Daas",
    "Daniël",
    "David",
    "Denise",
    "Desiree",
    "Dirk",
    "Dirkje",
    "Edgar",
    "Eduard",
    "Eliene",
    "Eline",
    "Esmée",
    "Esther",
    "Eva",
    "Eveline",
    "Evert",
    "Evi",
    "Fake",
    "Femke",
    "Filomena",
    "Folkert",
    "Francis",
    "Franco",
    "Frank",
    "Frans",
    "Frederik",
    "Freek",
    "Frits",
    "Gerard",
    "Gerardus",
    "Gerrit",
    "Gert",
    "Gideon",
    "Gijs",
    "Godelieve",
    "Goedele",
    "Goele",
    "Gudula",
    "Gustaaf",
    "Hadewig",
    "Hanna",
    "Hans",
    "Harm",
    "Hassan",
    "Hein",
    "Heleen",
    "Helena",
    "Helga",
    "Hella",
    "Hendrick",
    "Hendrik",
    "Henk",
    "Herman",
    "Hidde",
    "Hilarius",
    "Hubert",
    "Huberta",
    "Hubrecht",
    "Huib",
    "Huub",
    "Hylke",
    "Ida",
    "Ieneke",
    "Ienje",
    "Imke",
    "Ina",
    "Ineke",
    "Ines",
    "Ingrid",
    "Irene",
    "Iris",
    "Irma",
    "Isabella",
    "Jaap",
    "Jacob",
    "Jacoba",
    "Jacobus",
    "Jacqueline",
    "Jaike",
    "Jakob",
    "Jan",
    "Janneke",
    "Jannetje",
    "Jannie",
    "Jasmijn",
    "Jelle",
    "Jelmer",
    "Jens",
    "Jeroen",
    "Jet",
    "Jezus",
    "Jip",
    "Jo",
    "Joachim",
    "Job",
    "Jochem",
    "Johan",
    "Johanna",
    "Johannes",
    "Joke",
    "Jonas",
    "Joop",
    "Joost",
    "Jopie",
    "Jordi",
    "Jordy",
    "Joris",
    "Jos",
    "Josefien",
    "Josepha",
    "Josephine",
    "Josian",
    "Josien",
    "Josina",
    "Julia",
    "Juliana",
    "Jurre",
    "Karel",
    "Kees",
    "Kelly",
    "Kenzo",
    "Kim",
    "Klaas",
    "Koen",
    "Koenraad",
    "Laura",
    "Laurens",
    "Leendert",
    "Leentje",
    "Leo",
    "Leopold",
    "Lia",
    "Lidewij",
    "Lieke",
    "Lies",
    "Lieve",
    "Lieven",
    "Linda",
    "Lisa",
    "Lodewijk",
    "Loes",
    "Lola",
    "Lore",
    "Lotte",
    "Louis",
    "Lucas",
    "Luk",
    "Luuk",
    "Maaike",
    "Maarten",
    "Maartje",
    "Machteld",
    "Madelief",
    "Manon",
    "Marcus",
    "Margreet",
    "Margriet",
    "Maria",
    "Marie",
    "Marieke",
    "Mariëlle",
    "Marietje",
    "Marije",
    "Marijke",
    "Mario",
    "Maritje",
    "Marius",
    "Marja",
    "Mark",
    "Martijn",
    "Martinus",
    "Matthijs",
    "Maud",
    "Maurits",
    "Max",
    "Maximiliaan",
    "Maya",
    "Mees",
    "Meeuwis",
    "Meike",
    "Menno",
    "Michel",
    "Michiel",
    "Mie",
    "Miek",
    "Mieke",
    "Miep",
    "Mies",
    "Miriam",
    "Mirjam",
    "Monica",
    "Monique",
    "Naomi",
    "Natasja",
    "Nathalie",
    "Neeltje",
    "Nel",
    "Nico",
    "Nicolaas",
    "Niels",
    "Nienke",
    "Nora",
    "Peter",
    "Petra",
    "Petronella",
    "Petrus",
    "Pia",
    "Pien",
    "Pier",
    "Piet",
    "Pieter",
    "Pieternel",
    "Pieternella",
    "Pim",
    "Pol",
    "Priscilla",
    "Rebecca",
    "Remco",
    "Renaat",
    "Renate",
    "Rhijnvis",
    "Rie",
    "Rik",
    "Rinus",
    "Rivka",
    "Rob",
    "Robbert",
    "Robert",
    "Robin",
    "Roel",
    "Roeland",
    "Roelof",
    "Rogier",
    "Romaan",
    "Roos",
    "Ruben",
    "Ruud",
    "Sabine",
    "Salomon",
    "Sam",
    "Samuel",
    "Sander",
    "Sanne",
    "Sara",
    "Saskia",
    "Sebastiaan",
    "Sem",
    "Sieme",
    "Sietse",
    "Sietske",
    "Sjaak",
    "Sjaan",
    "Sjakie",
    "Spencer",
    "Stefan",
    "Stella",
    "Steven",
    "Stijn",
    "Sven",
    "Tessa",
    "Theo",
    "Thijs",
    "Thomas",
    "Tijn",
    "Tim",
    "Timo",
    "Tobias",
    "Tom",
    "Tomas",
    "Tonny",
    "Trees",
    "Trijntje",
    "Truus",
    "Valentijn",
    "Veerle",
    "Veerletje",
    "Vera",
    "Vlinder",
    "Wieb",
    "Wiebrand",
    "Wilco",
    "Wilhelmina",
    "Willem",
    "Willemijn",
    "Wim",
    "Wouder",
    "Wouter",
    "Iean",
    "Iacques",
    "Pierre",
    "Nicolas",
    "Guillaume",
    "Charles",
    "François",
    "Antoine",
    "Artus",
    "Audebert",
    "Barthelemi",
    "Christofle",
    "Claude",
    "Denys",
    "Estienne",
    "Firmin",
    "George",
    "Gerard",
    "Gilles",
    "Hubert",
    "Ioseph",
    "Lazare",
    "Marin",
    "Maximilian",
    "Noel",
    "Olivier",
    "Philippes",
    "Theodore",
    "Thomas",
    "Francois",
    "Louis",
    "Henri",
    "Michel",
    "Jean",
    "Jacques",
    "Charles",
    "Joseph",
    "Etienne",
    "Raymond"]

export const nicknameTable = 
    ["Capin’ ",
    "Dirty",
    "Squidlips",
    "Fish Face",
    "Bucky",
    "Two Toes",
    "Sharkbait",
    "Old",
    "Peg Leg",
    "Fluffbucket",
    "Scallywag",
    "Bucko",
    "Dead Man",
    "Matey",
    "Jolly",
    "Stinky",
    "Stubby",
    "Jolly Roger",
    "Mad",
    "Rascal",
    "Lady",
    "Brother",
    "Sister",
    "Papa",
    "Miss",
    "Freebooter",
    "From the West",
    "Byrd",
    "Jackson",
    "Sparrow",
    "Of the Coast",
    "Jones",
    "Ned Head",
    "Bart",
    "O’ Fish",
    "Kidd",
    "O’ Malley",
    "Barnacle",
    "Lobster Legs",
    "Hornswaggle",
    "McStinky",
    "Jungle",
    "Sea Wolf",
    "Beard",
    "Chumbucket",
    "Rivers",
    "Morgan",
    "McStubby",
    "Three Gates",
    "Bailey",
    "Of Atlantis",
    "Of Dark Water",
    "Task Rabbit",
    "Angel Slayer",
    "Oblique",
    "Dead Eyes",
    "Recluse",
    "Nashville Psycho",
    "Shivers",
    "Buttons",
    "Rundown",
    "Dust",
    "Aberration",
    "Voodoo",
    "West Indie Bandit",
    "Genesis",
    "Crimson",
    "Spasm ",
    "Checkmate",
    "Flatline",
    "Whip ",
    "Hex",
    "Malice",
    "Death Angel",
    "Strangler",
    "Diamondback",
    "One Leg",
    "Lonely Heart",
    "The Hunter",
    "Cut Throat",
    "Surge",
    "Nocturne",
    "Guilty Conscience",
    "Bipolar",
    "Crossfire",
    "Wild Onion",
    "Red Wolf",
    "Sick Sin",
    "Spike",
    "Chaos",
    "Whitewash",
    "Red Saint",
    "Inferno",
    "Swamp Skin",
    "One Eye",
    "Blackout",
    "The Leperchaun",
    "Limerick",
    "Snake",
    "Frenzy",
    "Mutilator",
    "Rampage",
    "Bedlam",
    "Felon",
    "Monster of Havana, Cuba",
    "Miracle",
    "Mudlark",
    "Upgrade",
    "Pitfall",
    "Three Sheets",
    "Cascade",
    "Spite",
    "Night Eyes",
    "Parallax",
    "Cannonball",
    "Burnout",
    "The Knife",
    "Pied Piper",
    "Solstice",
    "Tidal Wave",
    "Mimic",
    "Red Cloud",
    "Gunpoweder",
    "Standoff",
    "Burn",
    "Ropeburn",
    "Cold Cut",
    "Badness",
    "Mayhem",
    "Cyclone",
    "Vex",
    "Safari",
    "Ditch",
    "Blink",
    "West Indy",
    "East Indy",
    "Drift",
    "Mockingbird",
    "Whistler",
    "Tempus",
    "Venom",
    "Omega",
    "Stonewall",
    "Black",
    "Apex",
    "White",
    "Whight",
    "Red",
    "Misfit",
    "Blue",
    "Blur",
    "The Kingston Executioner",
    "Longshot",
    "Bloodline",
    "Tempo",
    "Patches",
    "Engineer",
    "Old Lace",
    "Pyro",
    "Grifter",
    "Sabbath",
    "Rewind",
    "Flood",
    "Hype",
    "Denizen",
    "Deuces Wild",
    "Slicer",
    "Sea Devil",
    "Ocean",
    "One Shot",
    "Salty",
    "Crabs",
    "Turtle",
    "Sea Turtle",
    "Cat’s Meow",
    "Queen Anders",
    "The Bride",
    "The Fisherman",
    "Zeus",
    "Seachest",
    "Mermaid",
    "Siren",
    "The Claw",
    "Hook",
    "The Barber",
    "Meathook",
    "The Knave",
    "Black Cat",
    "Tiger Eye",
    "Shakespeare",
    "Tortuga"]

export const lastNameTable = 
    ["Abirwik ",
    "Abyrford",
    "Adamson",
    "Akworth",
    "Alan",
    "Albright",
    "Albyn",
    "Aldestanemore",
    "Aldestanmore",
    "Allerton",
    "Allertonde",
    "Alne",
    "Ambler",
    "Ambrose",
    "Amyas",
    "Ancok",
    "Andrew",
    "Annetson",
    "Annottson",
    "Anotson",
    "Anson",
    "Apilby",
    "Appil Seller",
    "Appilby",
    "Appilton",
    "Appylton",
    "Arcle",
    "Arcles",
    "Arclez",
    "Arnald",
    "Arnalde",
    "Arnyngton",
    "Arowom",
    "Arowome",
    "Arrold",
    "Aserley",
    "Ashton",
    "Askam",
    "Askham",
    "Asper",
    "Asshton",
    "Asshwray",
    "Athereton",
    "Atkynson",
    "Aukland",
    "Austyn",
    "Aylde",
    "Ayled",
    "Ayredale",
    "Babthorp",
    "Bagon",
    "Bailgrave",
    "Bailly",
    "Bailya",
    "Bakar",
    "Baker",
    "Bakester",
    "Bakster",
    "Baldock",
    "Baldok",
    "Baleby",
    "Ball",
    "Balmaker",
    "Bampton",
    "Bank",
    "Barber",
    "Barbour",
    "Barden",
    "Bardeslay",
    "Bardislay",
    "Bardon",
    "Barker",
    "Barkour",
    "Barlay",
    "Barley",
    "Barn",
    "Barnard",
    "Barnby",
    "Barne",
    "Barneby",
    "Barnestonman",
    "Barnstonman",
    "Baron",
    "Barr",
    "Barry",
    "Barton",
    "Bartram",
    "Barwik",
    "Barwyk",
    "Baumburgh",
    "Baumeburgh",
    "Bauneburgh",
    "Bautry",
    "Bawde",
    "Bawmburgh",
    "Bawmer",
    "Baxester",
    "Baylbe",
    "Baylison",
    "Baynbrig",
    "Baynes",
    "Beamesley",
    "Becham",
    "Bedale",
    "Bedeman",
    "Bedford",
    "Bedlyngton",
    "Been",
    "Beese",
    "Beilbe",
    "Beilby",
    "Beile",
    "Beileby",
    "Beill",
    "Beilleres",
    "Bekwith",
    "Bekwyth",
    "Belamy",
    "Beleby",
    "Belenny",
    "Belford",
    "Bell",
    "Belle",
    "Bellowes",
    "Bellows",
    "Bellyngton",
    "Belos",
    "Beltoft",
    "Beme",
    "Bemeslay",
    "Bempton",
    "Ben",
    "Benclyff",
    "Bene",
    "Benett",
    "Benson",
    "Bentclyff",
    "Bentlay",
    "Bentley",
    "Berden",
    "Berebrewer",
    "Berebruer",
    "Berlot",
    "Bery",
    "Bese",
    "Beseby",
    "Best",
    "Beswyk",
    "Besyngby",
    "Beverlay",
    "Beverley",
    "Bewyk",
    "Beylbe",
    "Bigot",
    "Bilburgh",
    "Billesdale",
    "Bilton",
    "Birden",
    "Birlot",
    "Birnes",
    "Birnet",
    "Birnett",
    "Birstow",
    "Birtby",
    "Birtram",
    "Bisshop",
    "Bisshworth",
    "Bladesmyth",
    "Blakburn",
    "Blakelok",
    "Blakewell",
    "Blakhornaby",
    "Blaklok",
    "Blakwell",
    "Bland",
    "Blaunkfronnt",
    "Blaunkfront",
    "Blaunkfronte",
    "Blaunkfrount",
    "Blevet",
    "Blevett",
    "Blevit",
    "Blith",
    "Blyth",
    "Blythe",
    "Boiss",
    "Boland",
    "Bolland",
    "Bolron",
    "Bolton",
    "Borowe",
    "Bortrell",
    "Bosom",
    "Bossall",
    "Bosse",
    "Boswell",
    "Both",
    "Bothe",
    "Botrell",
    "Botterell",
    "Bouland",
    "Bouman",
    "Bours",
    "Bowe",
    "Bower",
    "Bowes",
    "Bowland",
    "Bowmer",
    "Bowre",
    "Bows",
    "Bowse",
    "Boyland",
    "Bracebrig",
    "Bracebrigg",
    "Bracebrigge",
    "Brad",
    "Bradefeld",
    "Bradeford",
    "Bradefot",
    "Bradefote",
    "Bradelay",
    "Braderig",
    "Bradfeld",
    "Bradford",
    "Bradfote",
    "Bradley",
    "Bradwell",
    "Bragnall",
    "Braithwate",
    "Braker",
    "Brampton",
    "Bramwith",
    "Bramwyth",
    "Brandesby",
    "Brathawayte",
    "Brathewayte",
    "Brathwayte",
    "Brawith",
    "Braythewate",
    "Braythwaite",
    "Braythwayte",
    "Braythwayth",
    "Breerton",
    "Brer",
    "Brerelay",
    "Brereton",
    "Brerton",
    "Brewer",
    "Brewester",
    "Brewster",
    "Brian",
    "Brid",
    "Brig",
    "Brigdam",
    "Briges",
    "Brigg",
    "Brigges",
    "Brigham",
    "Brignall",
    "Britt",
    "Brokden",
    "Broket",
    "Brokett",
    "Brokholez",
    "Brokker",
    "Broudster",
    "Broun",
    "Brounberd",
    "Broune",
    "Brounfeld",
    "Brounfeyld",
    "Brounflete",
    "Brown",
    "Browne",
    "Brownne",
    "Bruche",
    "Brugh",
    "Bryan",
    "Bryg",
    "Brygham",
    "Bryghton",
    "Bubwith",
    "Bubwyth",
    "Bucdene",
    "Bugthorp",
    "Bukdale",
    "Bukden",
    "Bukeler",
    "Bukeller",
    "Bukler",
    "Bullok",
    "Bulman",
    "Bulot",
    "Burbrig",
    "Burdon",
    "Burell",
    "Burgbrig",
    "Burges",
    "Burgez",
    "Burgh",
    "Burghbrig",
    "Burghbrigg",
    "Burghbryg",
    "Burghe",
    "Burgies",
    "Burlay",
    "Burley",
    "Burndale",
    "Burnelay",
    "Burnlay",
    "Burnley",
    "Burowe",
    "Burrell",
    "Bursell",
    "Burslay",
    "Burton",
    "Bussy",
    "Bustow",
    "Buthe",
    "Butler",
    "Butteler",
    "Buttiller",
    "Bygote",
    "Bynnett",
    "Bynnyng",
    "Byrd",
    "Byrdsall",
    "Byrt",
    "Byrtwesill",
    "Cade",
    "Cak",
    "Caldbek",
    "Calton",
    "Cambys",
    "Cambyssh",
    "Camerage",
    "Caminus",
    "Cammus",
    "Camsmyth",
    "Camus",
    "Candell",
    "Canomby",
    "Capmakar",
    "Capmaker",
    "Capper",
    "Cardemaker",
    "Cardmaker",
    "Care de Roy",
    "Cariour",
    "Carlele",
    "Carneby",
    "Carpenter",
    "Carre",
    "Carter",
    "Cartmale",
    "Carton",
    "Cartwright",
    "Castelford",
    "Castelton",
    "Castleton",
    "Catclogh",
    "Catcloghe",
    "Cateryk",
    "Catlogh",
    "Catlow",
    "Cato",
    "Catom",
    "Catour",
    "Catryk",
    "Catten",
    "Catton",
    "Cawod",
    "Caytour",
    "Celandre",
    "Celor",
    "Cendall",
    "Cesay",
    "Cesey",
    "Ceszay",
    "Ceszey",
    "Chambir",
    "Chapell",
    "Chapman",
    "Charleston",
    "Charleton",
    "Charters",
    "Chartirs",
    "Chatirs",
    "Chaumber",
    "Chaumbir",
    "Chaumbrelayne",
    "Chaumer",
    "Chaundler",
    "Chellowe",
    "Chemp",
    "Chestre",
    "Child",
    "Childe",
    "Chimnay",
    "Chymnay",
    "Chymney",
    "Claibruke",
    "Clapeham",
    "Clarell",
    "Claton",
    "Claybroke",
    "Claybruk",
    "Claybruke",
    "Clayton",
    "Clement",
    "Clerk",
    "Clerke",
    "Cleveland",
    "Clevyn",
    "Clevyng",
    "Cliderow",
    "Clif",
    "Clifton",
    "Cluyn",
    "Clyderow",
    "Clyderowe",
    "Clyff",
    "Clynt",
    "Cok",
    "Coke",
    "Cokehill",
    "Coker",
    "Cokhill",
    "Cokill",
    "Cokkir",
    "Col",
    "Cole",
    "Coller",
    "Collestaine",
    "Collestane",
    "Collestayne",
    "Collesthorp",
    "Collesthorpp",
    "Colling",
    "Collins",
    "Collyng",
    "Collynges",
    "Collyngs",
    "Collynnez",
    "Collynson",
    "Colstan",
    "Colstane",
    "Colstaune",
    "Colyer",
    "Colyng",
    "Colyng",
    "Colynnez",
    "Colyns",
    "Colynson",
    "Connesburgh",
    "Constabill",
    "Constable",
    "Cook",
    "Cooke",
    "Cooper",
    "Copper",
    "Cordener",
    "Corderay",
    "Corderoy",
    "Cornay",
    "Cornhill",
    "Cornwails",
    "Cort",
    "Costance",
    "Cotes",
    "Cotom",
    "Cotyngham",
    "Couk",
    "Couke",
    "Coup",
    "Coupeland",
    "Coupemanthorpp",
    "Couper",
    "Coupland",
    "Courtney",
    "Courtny",
    "Coutheron",
    "Coverdale",
    "Coverham",
    "Crake",
    "Crathorn",
    "Crathorne",
    "Craven",
    "Crawgill",
    "Crawgyll",
    "Cridlyng",
    "Cridyng",
    "Cristendem",
    "Crocelyn",
    "Croft",
    "Crofton",
    "Croghlyn",
    "Croglyn",
    "Crokebane",
    "Crokelyn",
    "Cropper",
    "Crosby",
    "Crossby",
    "Crosseby",
    "Croukebayn",
    "Croukebayne",
    "Croukey",
    "Croull",
    "Crukebayn",
    "Crukey",
    "Crydlyng",
    "Crydlyngton",
    "Cudbert",
    "Cuke",
    "Cuniesburgh",
    "Cuniesby",
    "Cunnesburgh",
    "Cunnesby",
    "Curesall",
    "Curtace",
    "Curtas",
    "Curteis",
    "Curtisse",
    "Custance",
    "D",
    "Dale",
    "Dalton",
    "Damysell",
    "Danby",
    "Dandeson",
    "Daneyell",
    "Danson",
    "Danyell",
    "Danysell",
    "Danyyhell",
    "Darnyngton",
    "Daunser",
    "Davey",
    "Davy",
    "Davyas",
    "Davyson",
    "Daweson",
    "Dawson",
    "Daymysell",
    "Dayvell",
    "de Acastre",
    "de Aldeburgh",
    "de Alne",
    "de Askham",
    "de Barkell",
    "de Barton",
    "de Bedale",
    "de Bolton",
    "de Bossall",
    "de Brereton",
    "de Brygenhall",
    "de Burowe",
    "de Burton",
    "de Buth",
    "de Buxton",
    "de Canon",
    "de Claxton",
    "de Clyfton",
    "de Cotyngham",
    "de Coverham",
    "de Craven",
    "de Cure",
    "de Dalton",
    "de Driffeld",
    "de Ellerson",
    "de Essham",
    "de Essheton",
    "de Esshton",
    "de Eston",
    "de Eton",
    "de Fenton",
    "de Gyll",
    "de Hay",
    "de Hayton",
    "de Helmelsay",
    "de Helmeslay",
    "de Hemelsay",
    "de Hesyll",
    "de Howom",
    "de Hull",
    "de Kyghlay",
    "de la Howe",
    "de Lairethorp",
    "de Laycestre",
    "de Leverton",
    "de Lynton",
    "de Malton",
    "de Marre",
    "de Marton",
    "de Mayfeld",
    "de Melburn",
    "de Moreby",
    "de Moreton",
    "de Muston",
    "de Neuton",
    "de Parke",
    "de Parre",
    "de Quixlay",
    "de Ripon",
    "de Sallay",
    "de Saxton",
    "de Snaythe",
    "de Stapilton",
    "de Strensall",
    "de Suton",
    "de Sutton",
    "de Thornton",
    "de Waldeby",
    "de Walton",
    "de Wartre",
    "de Welton",
    "de Weste",
    "de Whixlay",
    "de Wistowe",
    "de Wodhous",
    "de Wylton",
    "de Wystowe",
    "de York",
    "Deken",
    "Dekon",
    "del Awne",
    "del Bank",
    "del Banke",
    "del Bothe",
    "del Burgh",
    "del Hall",
    "del Hill",
    "del How",
    "del Howe",
    "del Hyll",
    "del Lee",
    "del More",
    "del Se",
    "del See",
    "Dele",
    "Deme",
    "Dene",
    "Denny",
    "Dennyn",
    "Dent",
    "Denton",
    "Deny",
    "Denys",
    "Depedale",
    "Depeden",
    "Dernyngton",
    "Deverell",
    "Dewe",
    "Doblay",
    "Dobley",
    "Dogeson",
    "Don",
    "Dondale",
    "Dorem",
    "Dounyr",
    "Dowdale",
    "Doweson",
    "Downom",
    "Drake",
    "Driffeld",
    "Dryng",
    "Drynge",
    "Dubber",
    "Dubley",
    "Duffeld",
    "Duffelde",
    "Dughty",
    "Duinvyll",
    "Dukdale",
    "Dunford",
    "Dunsford",
    "Dunstall",
    "Durant",
    "Durrant",
    "Dyconson",
    "Dyconson",
    "Dykonson",
    "Dykson",
    "Dynys",
    "Dyvill",
    "E",
    "Earl",
    "Ebor",
    "Ecopp",
    "Edewyn",
    "Edwyn",
    "Egremond",
    "Egremont",
    "Elerby",
    "Elis",
    "Ellerson",
    "Ellerton",
    "Elvet",
    "Elvyngton",
    "Elwald",
    "Elwyn",
    "Elys",
    "Elysson",
    "Emeryk",
    "Emlay",
    "Emrik",
    "Enveld",
    "Errard",
    "Escryk",
    "Essby",
    "Essham",
    "Essheton",
    "Esshewe",
    "Esshewra",
    "Esshton",
    "Esshwra",
    "Esshwraa",
    "Esteby",
    "Esterby",
    "Eston",
    "Estton",
    "Esyngwald",
    "Etton",
    "Evenwod",
    "Evinwod",
    "Evynwod",
    "F",
    "Fairefax",
    "Fairepoynt",
    "Fairfaux",
    "Fairfaux",
    "Fairfax",
    "Farefax",
    "Farepount",
    "Farepoynt",
    "Farnlay",
    "Farpoynt",
    "Faucet",
    "Faunconer",
    "Fausell",
    "Fauvell",
    "Fawcett",
    "Fayn",
    "Fayrefax",
    "Fayrepoynt",
    "Fayrfax",
    "Feiffer",
    "Feiser",
    "Feisour",
    "Felton",
    "Fenton",
    "Fereby",
    "Feriby",
    "Fernlay",
    "Fernley",
    "Ferrour",
    "Ferulay",
    "Fery",
    "Feryby",
    "Fesaunt",
    "Fideler",
    "Firthbank",
    "Fissh",
    "Fissher",
    "Fisshewyk",
    "Fisshwyk",
    "Flaxton",
    "Fleming",
    "Flemyng",
    "Flesshewer",
    "Fletcher",
    "Folifait",
    "Folyfayte",
    "Foreste",
    "Forster",
    "Forstere",
    "Foster",
    "Foston",
    "Foulbarn",
    "Foulford",
    "Fournas",
    "Fourneis",
    "Fournes",
    "Fourneys",
    "Fournis",
    "Fox",
    "Frank",
    "Franke",
    "Frankelayn",
    "Frassell",
    "Fraunk",
    "Fraunke",
    "Fraunkeleyn",
    "Fraunklay",
    "Fraunklayne",
    "Fraxton",
    "Freman",
    "Fresell",
    "Fressell",
    "Frissell",
    "Frost",
    "Frostell",
    "Frostrane",
    "Fryssell",
    "Fulford",
    "Fullford",
    "Fulshaw",
    "Fulshawe",
    "Fynch",
    "Fynche",
    "Fyrth",
    "Fyssh",
    "Fysshe",
    "Fyssher",
    "Fysshergate",
    "Fyssherwyf",
    "Fysshewyke",
    "Fysshwyk",
    "Fysshwyke",
    "G",
    "Gaile",
    "Gaille",
    "Gaire",
    "Galt",
    "Gardiner",
    "Gardyner",
    "Gare",
    "Garland",
    "Garliker",
    "Garlyker",
    "Garnet",
    "Garnett",
    "Garnter",
    "Garston",
    "Garten",
    "Garton",
    "Gate",
    "Gatescale",
    "Gatescalez",
    "Gatesheved",
    "Gaunt",
    "Gaunte",
    "Gayle",
    "Gayll",
    "Gaylle",
    "Gaynesburgh",
    "Gayre",
    "Gayt",
    "Gayton",
    "Gegges",
    "Geldale",
    "Genestes",
    "Giles",
    "Gill",
    "Gillyet",
    "Gillyot",
    "Girlington",
    "Girlyngton",
    "Girshop",
    "Girsop",
    "Girsshop",
    "Girssop",
    "Giry",
    "Giselay",
    "Glasen",
    "Glassen",
    "Glasyer",
    "Glasyn",
    "Glasyner",
    "Glewe",
    "Glone",
    "Glover",
    "Godale",
    "Goddesalve",
    "Godeshalve",
    "Godsalfe",
    "Godsalve",
    "Gold",
    "Goldale",
    "Goldesburgh",
    "Goldesmyth",
    "Goldsmyth",
    "Gollan",
    "Gosdale",
    "Goss",
    "Gossep",
    "Gott",
    "Gousehill",
    "Gower",
    "Gra",
    "Grantham",
    "Grastok",
    "Grauntham",
    "Gray",
    "Grayson",
    "Graystok",
    "Greenwell",
    "Grene",
    "Greneakirs",
    "Grenebanke",
    "Grenehod",
    "Grenehode",
    "Grenewell",
    "Grenewode",
    "Grenwod",
    "Greteham",
    "Gretham",
    "Greyson",
    "Griffyth",
    "Grissop",
    "Grissoppe",
    "Groull",
    "Gryme",
    "Grynder",
    "Gryssop",
    "Gud",
    "Gudale",
    "Gudderswik",
    "Guddeswyk",
    "Gude",
    "Gudeale",
    "Guislay",
    "Gunby",
    "Gurnard",
    "Gurnerd",
    "Gusherd",
    "Gutersworth",
    "Guttersworth",
    "Gutterswyk",
    "Guy",
    "Guye",
    "Guyl",
    "Guyour",
    "Gybson",
    "Gyles",
    "Gyll",
    "Gyor",
    "Gyrdeler",
    "Gyrlington",
    "Gyrshop",
    "Gyry",
    "Gys",
    "H",
    "Hadellsay",
    "Hadilsay",
    "Hadilsey",
    "Hadislay",
    "Hagg",
    "Hagge",
    "Hagges",
    "Hakworth",
    "Haliday",
    "Halifax",
    "Haliwell",
    "Hall",
    "Halliday",
    "Halsall",
    "Halyday",
    "Hambald",
    "Hamerton",
    "Hancok",
    "Hankes",
    "Hankok",
    "Hanselyn",
    "Hanslyn",
    "Hanson",
    "Hantelyn",
    "Hantrcroft",
    "Hapton",
    "Hardesang",
    "Hardestye",
    "Hardy",
    "Harington",
    "Harome",
    "Harpham",
    "Harrison",
    "Hart",
    "Harte",
    "Harton",
    "Harwod",
    "Haryngton",
    "Hastinges",
    "Hastynges",
    "Hastyngs",
    "Hatfeld",
    "Haucelyn",
    "Hauke",
    "Hauslyn",
    "Haustelyn",
    "Havercroft",
    "Hawardyne",
    "Hawke",
    "Haxay",
    "Haxey",
    "Hay",
    "Hayles",
    "Hayll",
    "Hayne",
    "Hayner",
    "Hayns",
    "Hayre",
    "Hayrester",
    "Hebbe",
    "Hebchester",
    "Heceham",
    "Hedon",
    "Hefeld",
    "Helay",
    "Helibisse",
    "Helm",
    "Helmeslay",
    "Helmesley",
    "Helmslay",
    "Helmyslay",
    "Helperby",
    "Hemelsay",
    "Hemelsey",
    "Hemylsay",
    "Henreson",
    "Henri",
    "Henrison",
    "Hereford",
    "Herfford",
    "Herpham",
    "Herrison",
    "Herryson",
    "Hert",
    "Hertford",
    "Hervy",
    "Heseham",
    "Hesil",
    "Hesill",
    "Hesillwod",
    "Hesilwod",
    "Heslyngton",
    "Hessill",
    "Hessilwod",
    "Hesyll",
    "Heton",
    "Hewett",
    "Hexes",
    "Hexham",
    "Hey",
    "Hiknorth",
    "Hilton",
    "Hipper",
    "Hirst",
    "Hissill",
    "Hochonson",
    "Hode",
    "Hogerd",
    "Hogeson",
    "Hoggarde",
    "Hoggeson",
    "Holbeck",
    "Holbek",
    "Holdernes",
    "Holgate",
    "Hollebek",
    "Hollegate",
    "Holles",
    "Holm",
    "Holme",
    "Holms",
    "Holtby",
    "Holteby",
    "Homby",
    "Hoppham",
    "Hopplyn",
    "Horbery",
    "Horbury",
    "Hornby",
    "Horner",
    "Hornyngton",
    "Horsford",
    "Hortford",
    "Horton",
    "Hostiler",
    "Hostiller",
    "Hotchonson",
    "Houson",
    "Hoveden",
    "Hovyngam",
    "Hovyngham",
    "Howden",
    "Howe",
    "Howet",
    "Howm",
    "Howme",
    "Howom",
    "Howram",
    "Howsom",
    "Hubank",
    "Huby",
    "Huchonson",
    "Hude",
    "Hudson",
    "Huet",
    "Hugate",
    "Huggate",
    "Hulot",
    "Hulott",
    "Hundmanby",
    "Hunmandby",
    "Hunt",
    "Hunte",
    "Hunter",
    "Huntyngton",
    "Hurkok",
    "Hurlok",
    "Husthwayt",
    "Hutchonson",
    "Huthom",
    "Hylton",
    "Hyndelay",
    "Hynderwell",
    "Hyndirwell",
    "Hyndlay",
    "Hyndson",
    "Hynse",
    "Hyrst",
    "I",
    "Ince",
    "Ingelby",
    "Ingilby",
    "Inglys",
    "Inglyssh",
    "Inglyssh",
    "Iranmonger",
    "Ironmanger",
    "Ironmanger",
    "J",
    "Jakes",
    "Jakeson",
    "Jakson",
    "Johnson",
    "Johnston",
    "Jonour",
    "Jonson",
    "Jowkyn",
    "Jowkyn",
    "Joynour",
    "Junkyn",
    "Junkyn",
    "Junour",
    "Jure",
    "Juynour",
    "K",
    "Ka",
    "Kaa",
    "Kamsmyth",
    "Karr",
    "Katton",
    "Kay",
    "Kechyn",
    "Kedell",
    "Keighlay",
    "Kek",
    "Kelay",
    "Keld",
    "Keley",
    "Kelk",
    "Keller",
    "Kelles",
    "Kemp",
    "Kendall",
    "Kendell",
    "Kenpper",
    "Kent",
    "Kepas",
    "Kepask",
    "Kerr",
    "Ketill",
    "Kettill",
    "Kettyll",
    "Ketyll",
    "Key",
    "Kighlay",
    "Kighlay",
    "Kighley",
    "Kilburn",
    "Killom",
    "Killynghall",
    "King",
    "Kirby",
    "Kirk",
    "Kirkby",
    "Kirke",
    "Kirkeby",
    "Kirkeham",
    "Kirkham",
    "Kirkton",
    "Knolles",
    "Knollez",
    "Knols",
    "Kyam",
    "Kyem",
    "Kyghley",
    "Kyng",
    "Kynge",
    "Kyngeston",
    "Kypas",
    "Kypask",
    "Kyrk",
    "Kyrkby",
    "Kyrkeby",
    "Kyrkeham",
    "Kyrkham",
    "Kyrkton",
    "L",
    "Lace",
    "Laicestre",
    "Lairethorpp",
    "Lairthorp",
    "Lam",
    "Lambe",
    "Lame",
    "Lamley",
    "Lancastre",
    "Lang",
    "Langland",
    "Langshawe",
    "Langton",
    "Lanom",
    "Lasbury",
    "Lassell",
    "Lasselles",
    "Lasset",
    "Lassett",
    "Lathom",
    "Laton",
    "Latoner",
    "Latonour",
    "Laurence",
    "Laycestre",
    "Layerthorp",
    "Layton",
    "Lechelay",
    "Lede",
    "Ledys",
    "Leedes",
    "Lefe",
    "Leicestre",
    "Lemyng",
    "Lepyngton",
    "Lesberi",
    "Lesberys",
    "Lescrop",
    "Lescrope",
    "Lessberys",
    "Lessebur",
    "Leste",
    "Lethelay",
    "Letheley",
    "Letwyn",
    "Leversham",
    "Levesham",
    "Levyngton",
    "Leycester",
    "Leycestre",
    "Lichefeld",
    "Lightfote",
    "Lighton",
    "Lillelay",
    "Lilly",
    "Lillylowe",
    "Lillyng",
    "Lincolne",
    "Litevyn",
    "Litewyn",
    "Litster",
    "Littelbery",
    "Littilbery",
    "Litubury",
    "Litveyn",
    "Litwyn",
    "Litwyne",
    "Lo",
    "Lokeryg",
    "Lokrig",
    "Lokryg",
    "Lokryk",
    "Lokryng",
    "Loksmith",
    "Loksmyth",
    "Lokyng",
    "Lokyngton",
    "Lolland",
    "Loncastre",
    "Lonesdale",
    "Lopyngton",
    "Lord",
    "Lorrimer",
    "Lorymer",
    "Lotterow",
    "Lounesburgh",
    "Lounesdale",
    "Louth",
    "Lowde",
    "Lucas",
    "Luce",
    "Lucy",
    "Lumhald",
    "Lumhals",
    "Lumman",
    "Lutton",
    "Lye",
    "Lyghtfote",
    "Lyllyng",
    "Lynby",
    "Lyncoln",
    "Lyncolne",
    "Lyndby",
    "Lyndelay",
    "Lyndesay",
    "Lyndeszay",
    "Lyndlay",
    "Lyndley",
    "Lynford",
    "Lynner",
    "Lynnynwever",
    "Lynton",
    "Lyon",
    "Lyons",
    "Lytster",
    "Lytwyn",
    "M",
    "Machion",
    "Madder",
    "Maddersay",
    "Mallom",
    "Manfeld",
    "Mansell",
    "Manware",
    "Mar",
    "Marchaunt",
    "Mareshall",
    "Maresshall",
    "Mariot",
    "Markett",
    "Marr",
    "Marre",
    "Marrsshall",
    "Marshall",
    "Marshton",
    "Marsshall",
    "Marsshton",
    "Marston",
    "Marton",
    "Maryner",
    "Maryot",
    "Masham",
    "Mason",
    "Masyng",
    "Maunby",
    "Maundevyle",
    "Maundvile",
    "Maundvile",
    "Maver",
    "Mawer",
    "Mayfeld",
    "Medcalf",
    "Medcalff",
    "Meddiall",
    "Mede",
    "Medelay",
    "Medeley",
    "Meeke",
    "Meke",
    "Melburn",
    "Melles",
    "Melryk",
    "Menythorp",
    "Merchand",
    "Merston",
    "Merton",
    "Messingham",
    "Messyngham",
    "Mesyngham",
    "Metcalfe",
    "Metcalff",
    "Meteham",
    "Metham",
    "Meux",
    "Meverous",
    "Middelham",
    "Middellham",
    "Middelton",
    "Middiall",
    "Middilton",
    "Middleton",
    "Midelton",
    "Midiall",
    "Midilton",
    "Mirre",
    "Misen",
    "Mitchell",
    "Mode",
    "Modeley",
    "Mody",
    "Monkton",
    "Moras",
    "Morasse",
    "More",
    "Moreby",
    "Moreland",
    "Mores",
    "Moresse",
    "Moreton",
    "Morland",
    "Morton",
    "Mortymer",
    "Moss",
    "Mosse",
    "Mote",
    "Mottram",
    "Moubray",
    "Moundevyle",
    "Mounser",
    "Mowbray",
    "Mowebray",
    "Mudd",
    "Muston",
    "Mylne",
    "Mylner",
    "Mytchell",
    "Myton",
    "Mytonde",
    "N",
    "Neilson",
    "Neleson",
    "Nelson",
    "Neuland",
    "Neuport",
    "Neuporte",
    "Neuton",
    "Nevell",
    "Nevile",
    "Nevill",
    "Nevyle",
    "Nevyll",
    "Newark",
    "Newe",
    "Newman",
    "Newport",
    "Newton",
    "Neylton",
    "Nobill",
    "Norfolk",
    "Norman",
    "Normanton",
    "Normanville",
    "North",
    "Northfolk",
    "Northfolke",
    "Northhouse",
    "Northman",
    "Northouse",
    "Northus",
    "Norton",
    "Nottyngham",
    "O",
    "Odlowe",
    "Okes",
    "Oklesthorp",
    "Ordeux",
    "Ordous",
    "Orlow",
    "Orwell",
    "Osmond",
    "Ostiler",
    "Ostiller",
    "Otlay",
    "Otter",
    "Otteray",
    "Ourom",
    "Oust",
    "Outlawe",
    "Owrom",
    "Owsteby",
    "Oy",
    "P",
    "Pacok",
    "Painntour",
    "Paisegod",
    "Paisgod",
    "Paisgode",
    "Paisgude",
    "Pak",
    "Pale",
    "Paline",
    "Pall",
    "Pallyn",
    "Palmer",
    "Panall",
    "Pannall",
    "Pannell",
    "Pape",
    "Paraunt",
    "Paret",
    "Parke",
    "Parkour",
    "Parkynson",
    "Parr",
    "Parre",
    "Parrote",
    "Parrott",
    "Parys",
    "Pataner",
    "Patener",
    "Patenmaker",
    "Patoner",
    "Patryk",
    "Patryngham",
    "Patyner",
    "Patynner",
    "Paule",
    "Paver",
    "Payner",
    "Paynter",
    "Payntour",
    "Peke",
    "Pennok",
    "Penreth",
    "Penton",
    "Peny",
    "Peper",
    "Pepir",
    "Percy",
    "Perers",
    "Peres",
    "Pereson",
    "Perman",
    "Person",
    "Persson",
    "Peton",
    "Piereson",
    "Pierson",
    "Pikeryng",
    "Pillay",
    "Pille",
    "Pilly",
    "Plasterer",
    "Plesyngton",
    "Plomer",
    "Plompton",
    "Plovell",
    "Plumber",
    "Plumer",
    "Plummer",
    "Plumpton",
    "Plumton",
    "Polayn",
    "Pollington",
    "Polly",
    "Pollyngton",
    "Polyngton",
    "Ponderson",
    "Pootherson",
    "Poremanger",
    "Port",
    "Porte",
    "Porter",
    "Portjoy",
    "Pothowe",
    "Pottak",
    "Potter",
    "Pouchemaker",
    "Poule",
    "Pouls",
    "Pountefreit",
    "Pountefreite",
    "Pountefret",
    "Pountefrett",
    "Pountfreit",
    "Pountfret",
    "Pountfrete",
    "Pountfrett",
    "Pountfreyt",
    "Pountfreyte",
    "Pounttfreyt",
    "Precious",
    "Prekett",
    "Preston",
    "Price",
    "Priket",
    "Prikett",
    "Prince",
    "Priston",
    "Proudefote",
    "Provest",
    "Prykkett",
    "Prynce",
    "Pryns",
    "Puddeszay",
    "Pudesay",
    "Pudeszay",
    "Pudsay",
    "Pudszay",
    "Pullan",
    "Punderson",
    "Py",
    "Pygot",
    "Pygott",
    "Pykall",
    "Pykarde",
    "Pykell",
    "Pykeryng",
    "Pymperton",
    "Pympton",
    "Pynchebek",
    "Pynchest",
    "Pynder",
    "Pynner",
    "Q",
    "Queldryk",
    "Quixlay",
    "R",
    "Raa",
    "Raby",
    "Radcliff",
    "Radclyf",
    "Radclyff",
    "Radclyffe",
    "Raghton",
    "Raisbek",
    "Raisebek",
    "Rallyn",
    "Rand",
    "Rande",
    "Rasebek",
    "Raskell",
    "Rasskell",
    "Raufe",
    "Raulyn",
    "Raynald",
    "Raynalde",
    "Rayner",
    "Raynold",
    "Raysebek",
    "Rede",
    "Remyngton",
    "Rest",
    "Revetour",
    "Richardson",
    "Richemond",
    "Riddeler",
    "Ridlay",
    "Ridley",
    "Rillington",
    "Rilly",
    "Rillyngton",
    "Rilyngton",
    "Rimour",
    "Riplingham",
    "Ripon",
    "Risse",
    "Rissheworth",
    "Risshford",
    "Roberdes",
    "Robinson",
    "Robson",
    "Rocliff",
    "Rocliffe",
    "Roclyff",
    "Roclyffe",
    "Rode",
    "Roderham",
    "Rodes",
    "Roeston",
    "Roger",
    "Rome",
    "Roodes",
    "Roos",
    "Roseton",
    "Rotherham",
    "Rothwell",
    "Roucliff",
    "Rouclyff",
    "Rouclyff",
    "Roune",
    "Routh",
    "Routhe",
    "Rowley",
    "Rowth",
    "Rudd",
    "Rudde",
    "Ruddel",
    "Ruddok",
    "Rukby",
    "Rukeby",
    "Rukey",
    "Rukshawe",
    "Rumby",
    "Rusebek",
    "Russell",
    "Ryddeley",
    "Rydley",
    "Ryllyngton",
    "Rymour",
    "Rypon",
    "Rys",
    "Ryse",
    "Ryselay",
    "Rysshworth",
    "Ryther",
    "S",
    "Saddiller",
    "Sadiller",
    "Sadler",
    "Sage",
    "Salesbury",
    "Sallay",
    "Saller",
    "Salley",
    "Salmon",
    "Salter",
    "Sander",
    "Sandford",
    "Sandre",
    "Sange",
    "Santon",
    "Sapemaker",
    "Sapper",
    "Saucemaker",
    "Saunde",
    "Saunder",
    "Saunderson",
    "Saundersson",
    "Sausceby",
    "Sauvage",
    "Savage",
    "Savagge",
    "Savege",
    "Sawer",
    "Sawere",
    "Sawman",
    "Sawmon",
    "Sawtre",
    "Sawyer",
    "Saxton",
    "Scalby",
    "Scamston",
    "Scargell",
    "Scargill",
    "Scargyll",
    "Scathelok",
    "Scathlok",
    "Scauceby",
    "Scaucesby",
    "Scauseby",
    "Scawesby",
    "Scawsby",
    "Scawsceby",
    "Scawseby",
    "Scirviner",
    "Sclatter",
    "Scotton",
    "Scrawesburgh",
    "Selby",
    "Selles",
    "Semer",
    "Semere",
    "Semester",
    "Seszay",
    "Seton",
    "Seynte",
    "Shaddlok",
    "Shadelok",
    "Shadelok",
    "Shadley",
    "Shapples",
    "Sharow",
    "Sharowe",
    "Sharp",
    "Sharpils",
    "Sharpp",
    "Sharrowe",
    "Shathelok",
    "Shathlok",
    "Shawe",
    "Sheffeld",
    "Shene",
    "Shepp",
    "Sheppils",
    "Sherlay",
    "Sherley",
    "Sherman",
    "Sheyn",
    "Shipman",
    "Shirlay",
    "Shirley",
    "Shirman",
    "Shirwod",
    "Shone",
    "Shorefeld",
    "Shoreswod",
    "Shorewod",
    "Shypton",
    "Shyrwod",
    "Shyrwud",
    "Skathelok",
    "Skelt",
    "Skelton",
    "Skipton",
    "Skipwith",
    "Skipwytth",
    "Skirmer",
    "Skott",
    "Skotton",
    "Skynner",
    "Skynwyn",
    "Skypwith",
    "Slater",
    "Sledman",
    "Small",
    "Smith",
    "Smyth",
    "Smythe",
    "Snart",
    "Snawe",
    "Snawesill",
    "Snawsell",
    "Sneusall",
    "Sokburn",
    "Somer",
    "Somerscales",
    "Sopper",
    "Sotheby",
    "Sothill",
    "Sounder",
    "Southam",
    "Southolme",
    "Sowerby",
    "Sowreby",
    "Sowtham",
    "Spatres",
    "Spayn",
    "Spayne",
    "Specer",
    "Spen",
    "Spence",
    "Spencer",
    "Spense",
    "Spenser",
    "Spicer",
    "Spofford",
    "Spoffoth",
    "Sponer",
    "Sporfford",
    "Sporier",
    "Spurer",
    "Spurre",
    "Spurret",
    "Spynk",
    "Spynke",
    "Spyser",
    "Stafford",
    "Stainelay",
    "Staivelay",
    "Stanehouse",
    "Stanlay",
    "Stans",
    "Stanton",
    "Stapilton",
    "Stapleton",
    "Stave",
    "Stavelay",
    "Staveley",
    "Stayneton",
    "Staynlay",
    "Staynton",
    "Stayvelay",
    "Stele",
    "Steresacre",
    "Steresaker",
    "Stereseacre",
    "Sterk",
    "Sterke",
    "Stevenour",
    "Steyneyhour",
    "Steynour",
    "Stillyngflete",
    "Stillyngflett",
    "Stir",
    "Stirk",
    "Stirston",
    "Stirtavaunt",
    "Stirtevaunt",
    "Stockton",
    "Stodeley",
    "Stokdale",
    "Stokeslay",
    "Stokesley",
    "Stoksley",
    "Stokton",
    "Storer",
    "Storiour",
    "Storour",
    "Storrs",
    "Strensall",
    "Strynger",
    "Stubbes",
    "Stubbez",
    "Studelay",
    "Studlay",
    "Studley",
    "Sturston",
    "Styllyngflete",
    "Sumnyr",
    "Sumpter",
    "Sutham",
    "Sutton",
    "Swan",
    "Swann",
    "Swathath",
    "Swathe",
    "Swerd",
    "Swynton",
    "Syggeston",
    "Symson",
    "Syther",
    "T",
    "Tailliour",
    "Taillour",
    "Taillyour",
    "Tailour",
    "Tailyour",
    "Takton",
    "Takyll",
    "Talkan",
    "Talliour",
    "Tandfeld",
    "Tanfeld",
    "Tanfelde",
    "Tarton",
    "Taundfeld",
    "Tayte",
    "Teiller",
    "Terry",
    "Tesdale",
    "Tesedale",
    "Thakwraa",
    "Thannibour",
    "Therlthwayte",
    "Thirn",
    "Thirsk",
    "Thomas",
    "Thomson",
    "Thoresby",
    "Thorne",
    "Thorneton",
    "Thornthayte",
    "Thornthewaytt",
    "Thornthwayte",
    "Thornton",
    "Thorp",
    "Thorpp",
    "Thrisk",
    "Thrysk",
    "Thuates",
    "Thuresby",
    "Thwayte",
    "Thwaytes",
    "Thwaytez",
    "Thwenge",
    "Thweyng",
    "Thweynge",
    "Thwinge",
    "Thwyng",
    "Tieller",
    "Tindall",
    "Tinndale",
    "Tirry",
    "Tirswell",
    "Tod",
    "Tokwith",
    "Tokwyth",
    "Toller",
    "Tolton",
    "Tomson",
    "Tonstall",
    "Tonte",
    "Topclyff",
    "Torney",
    "Toueton",
    "Touse",
    "Touthorp",
    "Touton",
    "Towton",
    "Trewe",
    "Truloffe",
    "Truswell",
    "Tubbac",
    "Tunstall",
    "Turlus",
    "Turnbul",
    "Turnour",
    "Tuswell",
    "Tutbag",
    "Tutbak",
    "Twys",
    "Tydeman",
    "Tyndale",
    "Tynley",
    "Tynly",
    "U",
    "Ughtred",
    "Ughtrede",
    "Ughtrete",
    "Ughtreth",
    "Uppesall",
    "Ursall",
    "Usburn",
    "Uscliff",
    "Useburn",
    "Useworth",
    "Usflete",
    "Usworth",
    "V",
    "Vause",
    "Vaux",
    "Venell",
    "Vicars",
    "Vowre",
    "Vyner",
    "Vynes",
    "Vyntener",
    "Vynter",
    "W",
    "Wade",
    "Wady",
    "Waid",
    "Waitheman",
    "Waithman",
    "Waithmer",
    "Wakefeld",
    "Waldbe",
    "Waldby",
    "Waldeby",
    "Walding",
    "Waldyby",
    "Waleby",
    "Waleys",
    "Walker",
    "Walkyngham",
    "Wall",
    "Waller",
    "Walles",
    "Wallesgrave",
    "Wallez",
    "Wallyngham",
    "Walsfurth",
    "Walshe",
    "Walsheford",
    "Walton",
    "Walus",
    "Wandiford",
    "Ward",
    "Wardale",
    "Warde",
    "Wardell",
    "Ware",
    "Warke",
    "Warmemouthe",
    "Warter",
    "Wartere",
    "Warthell",
    "Warthill",
    "Waryn",
    "Wastell",
    "Waterleader",
    "Waterleder",
    "Waterledyr",
    "Wath",
    "Watheman",
    "Wathman",
    "Watirleder",
    "Watson",
    "Wattes",
    "Watton",
    "Watyrleder",
    "Wawby",
    "Waystow",
    "Wayte",
    "Waytheman",
    "Waythman",
    "Webster",
    "Welburn",
    "Welez",
    "Wellam",
    "Welles",
    "Wellom",
    "Welton",
    "Wendre",
    "Werelay",
    "West",
    "Westeby",
    "Westiby",
    "Westmerland",
    "Westow",
    "Westowe",
    "Wetewod",
    "Wetewodd",
    "Wetewode",
    "Wever",
    "Whallegrave",
    "Whallesgrave",
    "Whar",
    "Wharfe",
    "Wharff",
    "Wheldale",
    "Whirig",
    "Whiryg",
    "Whitchester",
    "Whitchestre",
    "White",
    "Whitecak",
    "Whitecake",
    "Whitgyft",
    "Whittechestre",
    "Whixlay",
    "Whixley",
    "Whyrig",
    "Whyryg",
    "Whyryk",
    "Whyte",
    "Wighton",
    "Wigton",
    "Wileson",
    "Wilkyn",
    "Wilkynson",
    "Willett",
    "Williamson",
    "Willot",
    "Willott",
    "Willymote",
    "Wilote",
    "Wilson",
    "Wistow",
    "Wistowe",
    "Witton",
    "Wod",
    "Wodd",
    "Worcestre",
    "Wragby",
    "Wrangell",
    "Wrangill",
    "Wrangwissh",
    "Wrangyll",
    "Wrawby",
    "Wraweby",
    "Wright",
    "Wryght",
    "Wryghtson",
    "Wycam",
    "Wygeslay",
    "Wyghton",
    "Wygton",
    "Wylson",
    "Wylymot",
    "Wyman",
    "Wynder",
    "Wyndill",
    "Wyndin",
    "Wyndowes",
    "Wyndyll",
    "Wynfeld",
    "Wyngteryngham",
    "Wynpenny",
    "Wynter",
    "Wynteryngham",
    "Wyntryngham",
    "Wyntyngham",
    "Wyredale",
    "Wyresdale",
    "Wyryg",
    "Wystow",
    "Wystowe",
    "Wytam",
    "Wyvell",
    "Y",
    "Yereslay",
    "Yereslay",
    "Yerslay",
    "Yholgrave",
    "Yhoman",
    "Yhong",
    "Ynce",
    "Ynglyssh",
    "Yngylby",
    "Yolgrave",
    "Yollesgrave",
    "Yoman",
    "Yong",
    "Yonge",
    "York",
    "Yotton",
    "Yrinmonger",
    "Norrington",
    "Groves",
    "Flint",
    "Turner",
    "Greer",
    "del Aguila",
    "de Aguilar",
    "de Ahumada",
    "Aloy",
    "de Alvarado",
    "de Anania",
    "de Andrada",
    "de Anero",
    "de Aracena",
    "de Aranda",
    "de Araújo",
    "Arias",
    "de Avila",
    "de Baena",
    "de Baeza",
    "de Barrionuevo",
    "Barroso",
    "Blaque",
    "Blas",
    "de Burgos",
    "Caballero",
    "Cabezas",
    "de Cáceres",
    "de Calatrava",
    "del Canto",
    "Cardero",
    "Carrillo",
    "de Carrión",
    "de Castilla",
    "del Castillo",
    "de Castro",
    "Cerrada",
    "de Céspedes",
    "de la Corona",
    "de la Corredera",
    "Cortés",
    "de la Cruz",
    "de la Cueva",
    "Chico",
    "Díaz",
    "Díez",
    "Donoso",
    "Ejarque",
    "de Eslava",
    "de Espinosa",
    "Farfán",
    "Fillado",
    "de Foronda",
    "de la Fuente",
    "de Fuentes",
    "Galán",
    "García",
    "Gómez",
    "González",
    "Gutiérrez",
    "Hernández",
    "de Herrera",
    "del Hoyo",
    "de Jerez",
    "Jiménez",
    "Juárez",
    "de León",
    "de Lepe",
    "Lomelin",
    "López",
    "Luengo",
    "de Luján",
    "Macías",
    "de Marchena",
    "Marita",
    "Marqués",
    "Martín",
    "Martínez",
    "de Medina",
    "Méndez",
    "de Mendoza",
    "de Molina",
    "Monsalve",
    "Montañés",
    "de Montoya",
    "de Moraza",
    "Moreno",
    "Muñoz",
    "de Navarrete",
    "Nelleda",
    "Nuñez",
    "de Olivares",
    "de Onís",
    "Ortiz",
    "de Ovando",
    "de Ozayta",
    "de Palategui",
    "de Palencia",
    "de (la) Palma",
    "de Paz",
    "Peinado",
    "Perdigon",
    "Pérez",
    "Pinto",
    "Pizarro",
    "Pletes",
    "de Porras",
    "de Porres",
    "Quijada",
    "Ramírez",
    "Ramos",
    "de Ribera",
    "Riquel",
    "de Rivera",
    "de Robledo",
    "Rodríguez",
    "la Roja/el Rojo",
    "de Rojas",
    "Ruiz",
    "de Salazar",
    "de Samaniega",
    "de San Martín",
    "de San Millán",
    "Sánchez",
    "de Santa Cruz",
    "Sanz",
    "Sañudo",
    "de Saucedo",
    "de Segovia",
    "Solucio",
    "de Soto",
    "Suárez",
    "Suero",
    "de Tapia",
    "de Tejeda",
    "de la Torre",
    "de Torres",
    "de Trillo",
    "de Trujillo",
    "Vaca",
    "de Vadillo",
    "de Valderrama",
    "de Valencia",
    "del Valle",
    "Vázquez",
    "de Villanueva",
    "de Villarreal",
    "Vizcaína",
    "Yañez",
    "de Zárate",
    "Barrosa",
    "Aalbrecht",
    "Aantjes",
    "Aardema",
    "Aarts",
    "Aerts",
    "Akkerman",
    "Alberts",
    "Alders",
    "Altena",
    "Anderiesen",
    "Appelman",
    "Appelmans",
    "Arends",
    "Arents",
    "Averink",
    "Baas",
    "Bakker",
    "Beek",
    "Beets",
    "Beuren",
    "Boom",
    "Boon",
    "Boot",
    "Borst",
    "Bos",
    "Bosch",
    "Boschman",
    "Bot",
    "Botterman",
    "Broekman",
    "Brouwer",
    "Brugger",
    "Buijs",
    "Butler",
    "C",
    "Claeys",
    "Colruyt",
    "Cornelissen",
    "Couperus",
    "Cruyff",
    "David",
    "de Bie",
    "de Boer",
    "de Decker",
    "de Graaf",
    "de Groot",
    "de Haan",
    "de Haas",
    "de Heer",
    "de Jong",
    "de Klerk",
    "de Leeuw",
    "de Moor",
    "de Nijs",
    "de Rijke",
    "de Ruiter",
    "de Ruyter",
    "de Vos",
    "de Vries",
    "de Winter",
    "de Wit",
    "de Witt",
    "de Zwart",
    "Dehaene",
    "Dekker",
    "Devos",
    "Dijkgraaf",
    "Dijkstra",
    "Dirks",
    "Doedens",
    "Dork",
    "Drost",
    "Duif",
    "Duut",
    "Egmont",
    "Faas",
    "Feijen",
    "Fontein",
    "Fraenkel",
    "Franco",
    "Franken",
    "Fransen",
    "Franssen",
    "Goes",
    "Groeneveld",
    "Grotius",
    "Haas",
    "Hardenne",
    "Haverbus",
    "Heemskerk",
    "Hendriks",
    "Hennekes",
    "Heyting",
    "Hoek",
    "Hoekstra",
    "Huizinga",
    "Huurman",
    "Jacobs",
    "Jansen",
    "Janssen",
    "Janssens",
    "Jelle",
    "Jonkers",
    "Kamp",
    "Keijzer",
    "Keizer",
    "Kiefte",
    "Kint",
    "Kok",
    "Konijnenberg",
    "Kuil",
    "Kuiper",
    "Kuyper",
    "Landman",
    "Landzaat",
    "Lang",
    "Lansing",
    "Laurier",
    "Leegwater",
    "Lens",
    "Lenssen",
    "Lenstra",
    "Linssen",
    "Loman",
    "Loos",
    "Lorentz",
    "Luiken",
    "Luyten",
    "Lybaert",
    "Lyuten",
    "Maas",
    "Maes",
    "Maessen",
    "Manders",
    "Mansveld",
    "Maris",
    "Martens",
    "Meijer",
    "Melis",
    "Mertens",
    "Mokveld",
    "Mol",
    "Molenaar",
    "Moonen",
    "Muis",
    "Mulder",
    "Mynheer",
    "Nagel",
    "Nieman",
    "Nijdam",
    "Nijman",
    "Nijs",
    "Nijssen",
    "Oostelbos",
    "Persijn",
    "Peters",
    "Petraeus",
    "Pijl",
    "Ploegman",
    "Post",
    "Prins",
    "Provoost",
    "Quist",
    "Rademaker",
    "Raes",
    "Ras",
    "Ratgers",
    "Remmerswaal",
    "Rens",
    "Reus",
    "Reybrouck",
    "Reynders",
    "Reynst",
    "Rietveld",
    "Rigters",
    "Rijkens",
    "Rijsbrack",
    "Robbe",
    "Robben",
    "Roels",
    "Roelvink",
    "Rojer",
    "Rothschild",
    "Rots",
    "Rutte",
    "Rutten",
    "Sanders",
    "Schaap",
    "Scherpenzeel",
    "Schleck",
    "Schoenmakers",
    "Schoorl",
    "Sels",
    "Sier",
    "Smets",
    "Smid",
    "Smit",
    "Smits",
    "Sneijder",
    "Soete",
    "Steijn",
    "Sterk",
    "Stevens",
    "Stofberg",
    "Stolk",
    "Storms",
    "Stroker",
    "Sutphin",
    "Swart",
    "Swinkels",
    "Tasman",
    "Tempelman",
    "Terpstra",
    "Thijs",
    "Thomas",
    "Top",
    "Toso",
    "Uden",
    "Uhlenbeck",
    "Ursel",
    "Vaes",
    "Vaessen",
    "Valentijn",
    "Valk",
    "Valkenburg",
    "van Beuren",
    "van Bommel",
    "van de Berg",
    "van de Meer",
    "van den Berg",
    "van den Eijnde",
    "van der Asdonk",
    "van der Berg",
    "van der Laan",
    "van der Lans",
    "van der Lee",
    "van der Linden",
    "van der Made",
    "van der Meer",
    "van der Merwe",
    "van der Meulen",
    "van der Vliet",
    "van der Waals",
    "van der Zwan",
    "Van Dievoet",
    "van Dijk",
    "van Dort",
    "van Eijk",
    "van Essen",
    "van Etten",
    "van Hertrooij",
    "van Hoof",
    "Van Ingen",
    "van Leeuwen",
    "van Leeuwenhoek",
    "van Rossum",
    "van Vliet",
    "Vandievoet",
    "Veen",
    "Veerman",
    "Veltman",
    "Verbrugge",
    "Vercammen",
    "Vercauteren",
    "Vercruysse",
    "Verhofstadt",
    "Verkade",
    "Verkerk",
    "Verlinden",
    "Vermeer",
    "Vermeersch",
    "Vermeij",
    "Vermeiren",
    "Vermeulen",
    "Vermeyen",
    "Verschoor",
    "Verschueren",
    "Verschuren",
    "Verschuur",
    "Versluis",
    "Verstappen",
    "Versteeg",
    "Verstegen",
    "Verstraete",
    "Vervoort",
    "Verweij",
    "Vinck",
    "Vingboons",
    "Vink",
    "Vis",
    "Visser",
    "Vissers",
    "Vogel",
    "Vogels",
    "Vonk",
    "Vos",
    "Voskamp",
    "Vossen",
    "Vriend",
    "Vrolijk",
    "Wagenaar",
    "Walraven",
    "Warmerdam",
    "Wauters",
    "Weijers",
    "Wesseling",
    "Wessels",
    "Westerhof",
    "Westerveld",
    "Wever",
    "Wiegers",
    "Wiggers",
    "Wijnants",
    "Wijnen",
    "Wijnkoop",
    "Wildeboer",
    "Willems",
    "Willemse",
    "Willemsen",
    "Willink",
    "Winkel",
    "Winsemius",
    "Winter",
    "Wisse",
    "Witte",
    "Witteveen",
    "Wolff",
    "Wolfs",
    "Wolmarans",
    "Wolters",
    "Wouters",
    "Wuyts",
    "Wynant",
    "Zandberg",
    "Zegers",
    "Zeldenrust",
    "Zijm",
    "Zoet",
    "Zondervan",
    "Zonneveld",
    "Zuurman",
    "Zwaan",
    "Zwart",
    "Zwarts",
    "Zweerts",
    "Zwiers",
    "Zwolle",
    "Aleaume",
    "Allain",
    "Ballesdens",
    "Barat",
    "Basot",
    "Benedicti",
    "Bennart",
    "Berruyer",
    "Bouchelu",
    "Boucher",
    "Bouraine",
    "Bourdin",
    "Broichet",
    "Camus",
    "Carreau",
    "Cayn",
    "Chausse",
    "Chrestien",
    "Corneul",
    "Cosson",
    "Cousin",
    "Dampmartin",
    "Daumont",
    "Daval",
    "de Bailly",
    "de Birague",
    "de Bourbon",
    "de Chiverny",
    "de Dormans",
    "de Guise",
    "de la Chapelle aux Ursins",
    "de la Guesle",
    "de Lansac",
    "de Lassus",
    "de Lenoncourt",
    "de Lorraine",
    "de Lyon",
    "de Malicorne",
    "de Mayenne",
    "de Pectu",
    "de Poretus",
    "de Quitte-beuf",
    "de Rambouillet",
    "de Rosiere",
    "de Ruffec",
    "de Schomberg",
    "de Thou",
    "de Varade",
    "de Vaudemont",
    "de Villequier",
    "des Chasteliers",
    "Desiré",
    "d’Espesse",
    "Des-prez",
    "du Guet",
    "du Mesnil",
    "Everard",
    "Falaize",
    "Faulcon",
    "Faye",
    "Fournier",
    "Gaillard",
    "Gauthier",
    "Gemelli",
    "Godier",
    "Goux",
    "Groult",
    "Guilland",
    "Guychenot",
    "Idoine",
    "la Guesle",
    "le Febure",
    "le Hongre",
    "le Maistre",
    "Maceré",
    "Maheur",
    "Maillard",
    "Moreau",
    "Muldrac",
    "Nicolas",
    "Paillet",
    "Paris",
    "Pelletier",
    "Peron",
    "Pugnance",
    "Rouzee",
    "Tanquerel",
    "Thierry",
    "Viel",
    "Vigneron",
    "Violet"]

// strength, agility, presence, toughness, spirit
export const classStatsModifierTable = [[1, 0, -1, 1, -1], [-1, 2, 0, -1, 0], [0, -1, 2, 0, -1], [1, 1, -1, 0, -1], [0, -1, 0, -1, 2], [-1, 0, 0, -1, 2], ]

export const bruteAbilityTable = 
["You begin with a trusted weapon [d6]. It has a nickname (what is it?). It will never break (but can be dropped). DR10 to attack:"]

export const bruteWeaponTable = 
    ["Brass Anchor. Requires two hands, d8 damage. Reduces target's armor by one tier (-d2) during the attack.",
    "Whaling Harpoon. d8 damage, can be thrown by testing agility DR10.",
    "Meat Cleaver. Caked in layers of dried blood. d4 damage, and on a damage roll of 1 it spreads a disease from one of its prior victims: the target loses d6 HP at the start of its next two turns.",
    "Part of a Broken Mast. It has a rusted nail protruding from one end. d8 damage, plus an extra d6 on a critical hit.",
    "Large, Runic Machete. d6 damage. Great for chopping down vines and fopdoodles. It glows in the dark if there are undead nearby.",
    "Rotten Cargo Net. Test Agility DR12 to throw it at something and stop it from moving for d2 rounds. Trapped targets take d2 damage/round."]

export const rapscallionAbilityTable = 
    ["Back Stabber. If you attack by surprise (from hiding, distracted enemy, etc.) lower the attack DR by 2 and deal d2 extra damage.",
    "Burglar. You begin with lock picks. Pickpocket, disarm an enemy, or disable a trap: -4 to DR.",
    "Skylarker. You’re as nimble in the rigging as you are on the deck. If you attack after swinging, jumping, or making an acrobatic maneuver, test Agility DR10 to automatically hit and deal +2 damage.",
    "Sneaky Bastard. When striking from the shadows or while sneaking, test Agility DR12. On a success you automatically deal a critical hit.",
    "Lucky Devil. Whenever you use the Devil’s Luck, draw a card: 9+: Regain 1 Luck. Joker: Roll on the Joker Table (page 37).",
    "Grog Brewer. Each day you can brew d4 servings of potent grog. You can soak melee weapons in it to use as a poison Grog in a wound: Test Toughness DR14 or -d6 HP"]

export const buccaneerAbilityTable = 
    ["Wildlife Tracker. Ability tests related to mapping, navigating, treasure hunting, finding & disarming traps, and tracking prey are -3 DR.", 
    "Crack Shot. All ranged attacks are -2 DR.",
    "Fix Bayonets! You now have a bayonet (d4). You can attack with it on the same turn you reload.",
    "Focused Aim. Attacks against enemies you have already shot at during this combat are -4 DR to hit",
    "Buccan Cook: Months of island life have taught you the art of cooking meats over the buccan fire. You start with d8 rations of exquisite smoked meat. Eating it immediately recovers d4 HP, and you can make d4 more rations from any edible animal you kill.",
    "Survivalist: Your body has developed into a finely tuned machine for existing in the wild. Gain +1 Toughness. You cannot become infected, sick, or poisoned, and your maximum HP increases by d4."]

export const buccaneerWeaponTable =
    ["You start with a musket, and reloading takes 1 round instead of 2."]

export const swashbucklerAbilityTable = 
    ["Ostentatious Fencer: Your melee Attack/Defense is DR10 when wielding a rapier or cutlass. When dueling one-on-one, you deal +1 damage.",
    "Flintlock Fanatic. You can attack with up to three pistols on your turn (if you have them). Reloading one pistol only takes you one round.",
    "Scurvy Scallywag: You don’t fight fair. -2 DR when attacking an enemy that has already been attacked this turn.",
    "Inspiring Leader. Once each combat, roll a d4. Each of your allies may add or subtract that value from any one roll during this combat.",
    "Knife Knave. You start with 2 knives, and when attacking with them you can make two attacks a turn. They are DR10 to hit, and if the first attack hits, the 2nd is an auto-hit.",
    "Black Powder Poet. You start with explosives. Roll d4 times on the Bombs table (pg. 53). Your DR is -2 when throwing bombs."]

export const zealotAbilityTable = 
    ["Heal. Heal thyself or another for d8 HP.",
    "Curse. Test Spirit DR10: deal d8 + Spirit damage to an enemy that thou cannot see. DR8 if it has already been hurt in this fight.",
    "Death Ward. Touch the corpse of one who hath just died and test Spirit DR10: they return to life with 1 HP. Crit: Full HP. Fumble: They come back as a zombie and attacketh thee!",
    "Control Weather. Test Spirit DR10 to change the direction of the wind. If thou succeedeth by 5 or more, thou can also conjure or repel precipitation. Crit: Lightning striketh thine enemy, d12. Fumble: Lightning strikes thee for d6.",
    "Blessed Guidance. Thou may add d4 to any roll thee or another player maketh. Use this at any time, including after a roll (does not taketh thy action).",
    "Holy Protection. Thou or thine ally gets -4 to DR to defend for one attack. Use this at any time, including after a roll (does not taketh thy action).",
    "Divine Light. Bright light radiates from thee for up to d6 x 10 minutes. Enemies that see it are -2 DR to defend against.",
    "Silence. For the next 2d6 x 10 minutes, everything within 25' of thee maketh no sound. The effect only ends when the time doth expire.",
    "Sanctuary. All thy brethren in sight heal d4 HP.",
    "Commune. Test Spirit DR8: Asketh thy deity a singular “yay” or “nay” query. Thy response may be “unclear” or thou may receiveth no answer."]

export const zealotWorshipTable = 
    ["THE ONE TRUE GOD", "MOTHER NATURE", "THE ANCIENT GODS", "CHAOS", "THE DEEP", "THE DARK ONE", "THE CHURCH", "THE GREAT OLD ONE"]

export const sorcererAbilityTable = ["Dead Head. You summon a flying, ghostly skull. You may spend your action and test SPIRIT DR12 to have it deal damage to 1 target. It disapates after 1 minute or if it deals any damage. Fumble: it attacks you. It ignores armor and deals: I.2d4",
"Spiritual Possession. One random creature is possessed by a spirit or ghost. Ally: -2 DR to attack and defense. Enemy: -2 DR to attack or defend against it. Any Fumbles related to this creature cause the spirit to leave, stunning the host for 1 round. I.Lasts for d2 rounds",
"Protection. You summon a ghost or spirit to watch over the souls of you and your allies. Everyone who is protected gets -d2 protection for one hour as if wearing extra armor (does not affect penalties to Strength and Agility, not affected by Fumbles). I.1 soul",
"Clairvoyance. Ask the spirits a question about an adjacent room or area, though their answer may be a lie. Test SPIRIT to know if they are telling the truth. I.DR12",
"Necro-Sleep. A living creature appears to fall over dead, but when they awake they remember everything. Test SPIRIT DR12 to see if it falls “dead” asleep for: I.d2 rounds",
"Raise the Dead. You can create skeletal thralls from nearby corpses. They are stupid, but obey your verbal commands. They tumble into bones at sunrise. I.1 thrall"]

export const classAbilityTables = [bruteAbilityTable, rapscallionAbilityTable, buccaneerAbilityTable, swashbucklerAbilityTable, zealotAbilityTable, sorcererAbilityTable]

export const classDevilsLuckCircleFillTable = ["1", "1", "1", "1", "2", "2"]

export const classHpTable = [12, 8, 8, 10, 8, 8]

export const classClothingTable = [10, 6, 10, 10, 8, 6]

export const classHatTable = [12, 6, 12, 12, 0, 0]

export const classWeaponTable = [0, 6, 0, 10, 8, 0]